<template>
	<div class="view-wrapper view-list-wrapper">
		<div class="page-top-bg-addition" />
		<div class="mcontainer">
			<h1 class="title page-title">{{ itemsName.mult }}</h1>
			<div class="view-content-card card content-row">
				<div class="card-content">
					<Filterbar
						:items-loading="itemsLoading"
						:filters="filters"
						@setFilters="setFilters"
					/>

					<ItemsTableContainer
						ref="ItemsTableContainer"
						:items-loading="itemsLoading"
						:items-saving="itemsSaving"
						:table-data="itemsList"
						:table-settings="tableSettings"
						:items-name="itemsName"
						@event="handleEvent"
					/>

					<PaginationContainer
						:items-name="itemsName"
						:filters="filters"
						:meta="meta"
						@setFilters="setFilters"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { itemsDataMixin, eventHandler } from '@/mixins';
// import { filterstatusesList } from '@/constants/global';

export default {
	components: {
		Filterbar: () => import('../../components/Filterbar.vue'),
		ItemsTableContainer: () => import('../../components/ItemsTableContainer.vue'),
		PaginationContainer: () => import('../../components/PaginationContainer.vue')
	},
	mixins: [itemsDataMixin, eventHandler],

	computed: {
		...mapState({
			globalFilters: state => state.global.globalFilters,

			meta: state => state.filters.fetchedMeta,
			filters: state => state.filters.filters,
			itemsLoading: state => state.filters.isLoading,
			itemsSaving: state => state.filters.isSaving,
			itemsList: state => state.filters.itemsList
		}),

		// filterstatusesList: () => filterstatusesList,

		itemsName() {
			return {
				one: 'Фильтр',
				mult: 'Фильтров'
			};
		},

		tableSettings() {
			return {
				columns: [
					{ prop: 'id', label: '№', sortable: true, width: 60 },

					{
						prop: 'title_ru',
						label: 'Название',
						sortable: true
					}
				],
				operations: {
					edit: true,
					delete: true
				}
			};
		}
	},

	methods: {
		...mapActions({
			setup_navbar: 'setup_navbar',
			show_overlay: 'show_overlay',

			fetch_items: 'filters/fetch_filters',
			delete_item: 'filters/delete_filter',

			set_items: 'filters/set_filters',
			set_filters: 'filters/set_filters_filters'
		})
	}
};
</script>
